import { Header } from './components/Header.js';
import { Card } from './components/Card.js'
import { Colors, FirstCardImages as imgSet1 } from './components/Constants.js';
import './App.css';


function App() {
  document.body.style.overflow = "hidden"

  return (
    <div className="App">
      <Card title="Produkty" text="Produktové fotografie, nebo videa..." imageNames={imgSet1} color={Colors.primary}>
      </Card>
      <Card title="Produkty" text="Produktové fotografie, nebo videa..." imageNames={imgSet1} color={Colors.primary}/>
      <Header></Header>
      
    </div>
  );
}

export default App;
