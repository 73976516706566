const Colors = {
    primary: "#333138",
    secondary: "#222222",
    tertiary: "#B6B6B6",
    fourth: "#EEEEEE"
}

const CardSize = {
    width: "100vh",
    height: "95vw",
}

const FirstCardImages = [
    "/1.jpg",
    "/2.png",
    // "/3.png", UNCOMMENT WHEN THE PICTURE IS GOOD
    "/4.jpg",
    "/5.png"
]

export { Colors, CardSize, FirstCardImages };