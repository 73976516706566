import { React, useRef, useState } from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import { FirstCardImages, Colors } from "./Constants.js";
import 'pure-react-carousel/dist/react-carousel.es.css';

function Card(props) {

    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    const cont_height = useRef(vh);
    const cont_width = useRef(vw);

    // const [height, setHeight] = useState(0);
    // const [width, setWidth] = useState(0);

    // TODO: instead of fixed 16:9 ratio, do this with the aspect ratio of the actual image
    // if(  ) { // by height
    //     setHeight();
    // }

    function createImgSlide(index, img) {
        const random = Math.random();

        return (
            <Slide index={index} key={"key" + random}>
                <div style={{height: cont_height, width: cont_width, backgroundColor: "#A9A9A9"}}>
                    <Image className="Img" src={img} ></Image>
                    {/* <Image src={img} style={{scale: 150%""}}></Image> */}
                    <iframe src="img" title="..." loading="lazy"></iframe>
                </div> 
            </Slide>
        );
    }

    function getSlides() {
        var slides = [], x = 0;
        for(const imgPath of FirstCardImages) {
            slides.push(createImgSlide(x, imgPath));
            x++;
        }
        return slides;
    }

    const [hoverL, setHoverL] = useState("ArrowL Arrow-button");
    const [hoverR, setHoverR] = useState("ArrowR Arrow-button");

    return (
        <div className="Card" style={{backgroundColor: props.color}}>
            
            
            <CarouselProvider 
                naturalSlideWidth={16}
                naturalSlideHeight={9}
                totalSlides={FirstCardImages.length}
                infinite={true}
                hasMasterSpinner={true}
                >
                <Slider>
                    {getSlides()}
                </Slider>
                <ButtonBack className={hoverL} >
                    <svg viewBox="0 0 32 32" version="1.1" style={{fill: Colors.fourth}}>
                        <path d="M23.505 0c0.271 0 0.549 0.107 0.757 0.316 0.417 0.417 0.417 1.098 0 1.515l-14.258 14.264 14.050 14.050c0.417 0.417 0.417 1.098 0 1.515s-1.098 0.417-1.515 0l-14.807-14.807c-0.417-0.417-0.417-1.098 0-1.515l15.015-15.022c0.208-0.208 0.486-0.316 0.757-0.316z"></path>
                    </svg>
                </ButtonBack>
                <ButtonNext className={hoverR} >
                    <svg viewBox="0 0 32 32" version="1.1" style={{fill: Colors.fourth, transform: "rotate(180deg)"}}>
                        <path d="M23.505 0c0.271 0 0.549 0.107 0.757 0.316 0.417 0.417 0.417 1.098 0 1.515l-14.258 14.264 14.050 14.050c0.417 0.417 0.417 1.098 0 1.515s-1.098 0.417-1.515 0l-14.807-14.807c-0.417-0.417-0.417-1.098 0-1.515l15.015-15.022c0.208-0.208 0.486-0.316 0.757-0.316z"></path>
                    </svg>
                </ButtonNext>
                
            </CarouselProvider>
            { props.children }
        </div>
    )
}

export { Card };